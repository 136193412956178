import React from "react";  

export default function Blurb() {
    return (
        <>
        <h2>Welcome</h2>
        <p className="text-justify">Finding squash courts in London without an expensive private club membership can be difficult. Fortunately, 
          there are many "pay and play" options available across the city, and other online tools exist to help you find these locations. 
          However searching multiple leisure centre websites or apps to find a time you and your opponent can actually play isn't fun.
          This site aims to make it easier for you to find and book squash courts in London.
        </p>
        <p className="text-justify">Some courts, like those at Oasis, Finsbury, and Balham leisure centres, are in high demand, 
          particularly at peak times. Meanwhile, courts at less popular locations, such as Brixton, Tooting, and Porchester, are 
          often cheaper and easier to book. My goal is to help you discover new squash locations in your area. As local councils 
          cut funding, some courts may become underused or neglected, it's crucial to make use of these facilities so we don't risk 
          losing them.
        </p>

        <h4>History of Squash in London</h4>
        <p className="text-justify">London is where squash began. It evolved from 'rackets' which was played in London's prisons in the 19th century
          and became 'squash' at Harrow School in the 1830s. 1922 saw the first British Open at Queen's Club in West Kensington.
          In 2010, Sport England statistics showed that 500,000 people regularly played squash in England but by 2023 this had 
          declined to just over 250,000. The inclusion of squash in the 2028 Olympics in Los Angeles will boost the sport's
          popularity but it's important to keep the sport alive and thriving in London in the meantime. 
        </p>

        <h4>Links</h4>
        <ul>
          <li><a href="https://www.englandsquash.com/get-involved/play/where-to-play" target="_blank" rel="noreferrer">England Squash - Where to play squash</a></li>
          <li><a href="https://www.worldsquash.org/a-sport-with-history/" target="_blank" rel="noreferrer">World Squash - A sport with history</a></li>
          <li><a href="https://www.everyoneactive.com/content-hub/activities/squash/" target="_blank" rel="noreferrer">Play squash with Everyone Active</a></li>
          <li><a href="https://www.better.org.uk/what-we-offer/activities/squash" target="_blank" rel="noreferrer">Enjoy squash at Better</a></li>
          <li><a href="https://www.placesleisure.org/find-centre/" target="_blank" rel="noreferrer">Places Leisure - Find a centre</a></li>
          <li><a href="https://active.lambeth.gov.uk/activities/squash/" target="_blank" rel="noreferrer">Squash at Active Lambeth</a></li>
        </ul>
        </>
    )
}