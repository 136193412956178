import React, {useState, useEffect, useRef} from "react";
import { useLocation as useBrowserLocation } from "react-router-dom";
import FinderSearch from "./FinderSearch";
import FinderResponse,  { noSlotsError } from "./FinderResponse";
import {CentreID, Locations, providerSearchHorizon} from "../utils/LocationsList";
import GetAvailabilities, { ApiResponseLocation } from "../utils/GetAvailabilities";
import { formatTime, nextHour, timeOptions, dateOptions } from "../utils/Formatting";
import { DateTime } from "luxon";
import { Col, Row, Spinner } from "react-bootstrap";
import { filterResponseForTime, findClosestTimeSlots, getSlotDiff, slotIsAvailable } from "../utils/Logic";
import { haversineDistance } from "../utils/Geography";
import TextCard from "./TextCard";


const useQuery = () => {
    return new URLSearchParams(useBrowserLocation().search);
};

const validateQuery = (query: URLSearchParams) => {
    const refresh = query.get("refresh");
    const location = query.get("location");
    const validLocation = location && Object.keys(Locations).includes(location);

    if (refresh) {
        return {
            location: validLocation ? (location as CentreID) : null,
            refresh: true
        }
    }

    const date = query.get("date");
    const time = query.get("time");

    const validDate = date && dateOptions.map(d => d.toISODate()).includes(date);
    const validTime = time && timeOptions.includes(time);

    return {
        date: validDate ? date : null,
        time: validTime ? time : null,
        location: validLocation ? (location as CentreID) : null
    }
};

const reportConversion = (url?: string) => {
    if (typeof window.gtag_report_conversion === 'function') {
        window.gtag_report_conversion(url); // Call the function
    } else {
      console.warn('gtag_report_conversion function is not available');
    }
  };

function Finder({availabilityEndpoint} : { availabilityEndpoint: string }) {
    const query = useQuery();
    const browserLocation = useBrowserLocation();

    const validatedQuery = validateQuery(query);

    const initialDate = validatedQuery["date"] || DateTime.now().toISODate() as string;
    const initialTime = validatedQuery["time"] || nextHour();
    const initialLocation = validatedQuery["location"] || "Balham";

    const [requestError, setRequestError] = useState<string|null>(null);
    const [date, setDate] = useState(initialDate as string);
    const [time, setTime] = useState(initialTime);
    const [searchMethod, setSearchMethod] = useState('centre');
    const [outsideArea, setOutsideArea] = useState(false);
    const [searchTooFarOut, setSearchTooFarOut] = useState(false);
    const [location, setLocation] = useState<CentreID>(initialLocation);
    const [searchLoading, setSearchLoading] = useState(false);
    const [response, setResponse] = useState<ApiResponseLocation[]|undefined>(undefined);
    const [altsLoading, setAltsLoading] = useState(false);
    const [altResponse, setAltResponse] = useState<ApiResponseLocation[]|undefined>(undefined);
    const [altRequestError, setAltRequestError] = useState<string|null>(null);
    const autoSearched = useRef(false);

    const SEARCH_TOLERANCE = 30; //minutes
    const ALT_SEARCH_TOLERANCE = 45; //minutes

    const checkSearchIsValid = () => {
        const provider = Locations[location].provider;
        const horizon = providerSearchHorizon[provider];
        const searchDate = DateTime.fromISO(date).startOf('day');
        const now = DateTime.now().startOf('day');
        const daysAhead = searchDate.diff(now, 'days').days;
        setSearchTooFarOut(daysAhead > horizon);
    }

    const dateHandler = (d: string) => {
        console.log(`Setting date: ${d}`)
        setDate(d);
    }

    const timeHandler = (t: string) => {
        const formattedTime = formatTime(t)
        console.debug(`Setting time: ${formattedTime}`)
        setTime(formatTime(formattedTime))
    }

    const coordinatesHandler = (coords: { lat: number, lng: number }) => {
        let closestLocation = "Balham" as CentreID
        let closestDistance = Infinity
        for (const locationKey of Object.keys(Locations) as CentreID[]) {
            const location = Locations[locationKey]
            const distance = haversineDistance(coords, location.coordinates)
            if (distance < closestDistance) {
                closestDistance = distance
                closestLocation = locationKey
            }
        }
        setOutsideArea(closestDistance > 30)
        setLocation(closestLocation)
    }

    const searchMethodHandler = (selected: string) => {
        setSearchMethod(selected)
        if (selected === 'centre') {
            setOutsideArea(false)
        }
        if (selected === 'location') {
            setResponse(undefined)
            setAltResponse(undefined)
            setRequestError(null)
            setAltRequestError(null)
        }
    }
  
  
    const locationHandler = (selected: HTMLCollectionOf<HTMLOptionElement>) => {
      const selectedOptionsArray = [].slice.call(selected).map((item: HTMLOptionElement) => item.value)
      const location = selectedOptionsArray[0] as CentreID;
      console.debug(`Setting location ${JSON.stringify(location)}`)
      setLocation(prev => location ?? prev);
    };

    const searchHandler =  async () => {
        setSearchLoading(true);
        reportConversion();

        console.debug(`Searching with ${date}, ${time}, ${JSON.stringify(location)}`)
        setRequestError(null);
        setResponse(undefined);
        setAltResponse(undefined);
        const [apiResponse, apiError] = await GetAvailabilities(availabilityEndpoint, date, date, [location]);
        setRequestError(apiError);
        setResponse(apiResponse);
        setSearchLoading(false)
        await searchAlternatives(apiResponse, time);
        const newUrl = `?date=${date}&time=${time}&location=${location}`;
        window.history.pushState(null, '', newUrl);
    }; 

    const filterSlotsToAvailable = (resp: ApiResponseLocation[]) => {
        return resp[0].dates[0].slots.filter((s) => slotIsAvailable(s))
    }

    const anySlotsAvailable = (resp: ApiResponseLocation[]) => {
        return filterSlotsToAvailable(resp).length > 0;
    }

    const filterAlternativeSitesBySearchHorizon = (searchDate: string, sites: CentreID[]) => {
        return sites.filter((site) => {
            const provider = Locations[site].provider;
            const horizon = providerSearchHorizon[provider];
            const parsedSearchDate = DateTime.fromISO(searchDate).startOf('day');
            const now = DateTime.now().startOf('day');
            const daysAhead = parsedSearchDate.diff(now, 'days').days;
            return daysAhead <= horizon;
        });
    }

    const handleAlternativeSearch = async (location: CentreID, date: string, searchTime: string) => {
        const altLocations = filterAlternativeSitesBySearchHorizon(date, Locations[location].alternativeSites);
        console.log(`Searching alternatives at: ${JSON.stringify(altLocations)}`)
        setAltsLoading(true);
        const [apiResponse, apiError] = await GetAvailabilities(availabilityEndpoint, date, date, altLocations);
        setAltsLoading(false);
        setAltRequestError(apiError);
        const filteredResponse = filterResponseForTime(apiResponse, searchTime, ALT_SEARCH_TOLERANCE);
        setAltResponse(filteredResponse);
    };

    const searchAlternatives = async (firstResponse: ApiResponseLocation[], searchTime: string) => {
        if (firstResponse[0] && firstResponse[0].dates[0]) {
            const availableSlots = filterSlotsToAvailable(firstResponse);
            const responseClosestSlots = findClosestTimeSlots(availableSlots, searchTime)
            
            if (responseClosestSlots.closest) {
                console.log(`closest slot: ${JSON.stringify(responseClosestSlots.closest)} searchTime: ${searchTime} }`)
                const absDiff = Math.abs(getSlotDiff(responseClosestSlots.closest, searchTime))
                console.log(`diff is ${absDiff}`)
                
                if (absDiff > SEARCH_TOLERANCE) {
                    await handleAlternativeSearch(location, date, searchTime);
                }
            
            } else if (availableSlots.length === 0) {
                await handleAlternativeSearch(location, date, searchTime);
            }
        }
    }

    const hasSlots = (resp: ApiResponseLocation) => {
        if (resp.dates[0]) {
            return resp.dates[0].slots.length > 0;
        } else {
            return false;
        }
    }
    
    const altHasSlots = (resp: ApiResponseLocation[]) => !(resp.map(l => hasSlots(l)).every(b => b === false));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [browserLocation])

    useEffect(() => {
        if (validatedQuery["date"] && validatedQuery["time"] && validatedQuery["location"] && !autoSearched.current) {
            autoSearched.current = true;
            searchHandler();
        }
    }, [validatedQuery]);

    useEffect(() => {
        checkSearchIsValid();
    }, [date, location]);

    useEffect(() => {
        if (validatedQuery["refresh"])
            setResponse(undefined);
            setLocation(initialLocation);
            setTime(initialTime);
            setDate(initialDate);
    }, [validatedQuery["refresh"]]);
    
    return (
        <>
            <Row>
                <Col>
                    <FinderSearch
                        selectedDate={date}
                        selectedTime={time} 
                        selectedLocation={location}
                        selectedSearchMethod={searchMethod}
                        outsideArea={outsideArea}
                        searchTooFarOut={searchTooFarOut}
                        onSelectDate={dateHandler} 
                        onSelectTime={timeHandler} 
                        onSelectSearchMethod={searchMethodHandler}
                        onSetUserCoords={coordinatesHandler}
                        onSelectLocation={locationHandler} 
                        onSearch={searchHandler}
                        isLoading={searchLoading}
                    />
                </Col>
            </Row>
            <Row>
                {!requestError && response && response[0] && (
                    <Col className="ms-2">
                        <FinderResponse 
                            baseUrl={availabilityEndpoint} 
                            location={response[0].location} 
                            date={date} 
                            time={time} 
                            response={response[0]} 
                            isAlternative={false}
                            manuallySelectedLocation={searchMethod === 'centre'}
                        />
                    </Col>
                )}
                {requestError && requestError !== noSlotsError && <div className="p-2" style={{marginLeft: '1em'}}>{requestError}</div>}
            </Row>
            <Row>
                <Col>
                    {altsLoading && (
                        <div className="p-2">
                            <p>No courts around selected time. Loading other options...</p>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )}
                </Col>
            </Row>
            {
                response && !altsLoading && altResponse && (

                    <TextCard content={
                        altHasSlots(altResponse) ? (
                            searchMethod === 'centre' ? (
                                anySlotsAvailable(response) ? (
                                    <span>
                                        Availability isn't great at your selected time. <br />  
                                        Explore other options below:
                                    </span>
                                ) : (
                                    <span>
                                        There aren’t any courts at your chosen centre close to your selected time.<br />
                                        Explore other options below:
                                    </span>
                                )
                            ) : (
                                anySlotsAvailable(response) ? (
                                    <span>
                                        Availability isn't great at your selected time.<br />
                                        Explore other options below:
                                    </span>
                                ) : (
                                    <span>
                                        Your nearest courts, at {Locations[location].displayName}, don’t have any availability close to your selected time.<br /> 
                                        Explore other options below:
                                    </span>
                                )
                            )
                        ) : (
                            searchMethod === 'centre' ? (
                                anySlotsAvailable(response) ? (
                                    <span>
                                        Availability isn't great at your selected time.<br />
                                        Nearby centres don't have availability either. Try a different search.
                                    </span>
                                ) : (
                                    <span>
                                        There aren’t any courts at your chosen centre or nearby centres around your selected time.<br />
                                        Try a different search.
                                    </span>
                                )
                                
                            ) : (
                                anySlotsAvailable(response) ? (
                                    <span>
                                        Availability isn't great at your selected time.<br /> 
                                        There’s no availability at other courts nearby. Try a different search.
                                    </span>
                                ) : (
                                    <span>
                                        Your nearest courts, at {Locations[location].displayName}, don’t have any availability close to your selected time.<br /> 
                                        There’s no availability at other courts nearby. Try a different search.
                                    </span>
                                )
                            
                            )
                        )
                                   
                    } />

                    
                )
            }
            <Row>
                {!altRequestError && altResponse && (
                    <Col>
                        {altResponse.map((apiResult) => (
                            hasSlots(apiResult) && (
                                <FinderResponse 
                                    baseUrl={availabilityEndpoint} 
                                    location={apiResult.location} 
                                    date={date} 
                                    time={time} 
                                    response={apiResult} 
                                    isAlternative={true}
                                    manuallySelectedLocation={searchMethod === 'centre'}
                                />
                            )
                        ))}
                    </Col>
                )}
            </Row>
        </>
    );
}

export default Finder;