import { Coords } from "./Geography"

export type Provider = 'places' | 'better' | 'activeLambeth' | 'everyoneActive'


export type CentreID = "Balham" 
    | "oasis-sports-centre" 
    | "Tooting" 
    | "Tolworth" 
    | "Epping" 
    | "britannia-leisure-centre" 
    | "swiss-cottage-leisure-centre" 
    | "kensington-leisure-centre" 
    | "clissold-leisure-centre" 
    | "walthamstow-leisure-centre" 
    | "crystal-palace-leisure-centre" 
    | "canons-leisure-centre" 
    | "waterfront-leisure-centre" 
    | "hammersmith-fitness-squash-centre" 
    | "finsbury-leisure-centre" 
    | "brixton-recreation-centre" 
    | "flaxman-sports-centre" 
    | "ironmonger-row-baths" 
    | "QueenMother" 
    | "WestwayPortobello" 
    | "Porchester" 
    | "Cheam" 
    | "Harrow"

enum TransportMode {
    Tube,
    NationalRail,
    Overground
}

export type Transport = {
    mode: TransportMode,
    station: string
}

export type CourtLocation = {
    provider: Provider,
    displayName: string,
    alternativeSites: CentreID[],
    transport: Transport,
    coordinates: Coords,
    districtName: string,
    courtCount: number
    embedMapUrl?: string
}

type Region = {
    regionName: string,
    centreIDs: CentreID[]
}

export const Regions: Record<string, Region> = {
    "west": {regionName: "West", centreIDs: ["Porchester", "hammersmith-fitness-squash-centre", "kensington-leisure-centre", "WestwayPortobello", "swiss-cottage-leisure-centre", "Harrow"]},
    "central": {regionName: "Central", centreIDs: ["oasis-sports-centre", "QueenMother"]},
    "south-west": {regionName: "South West", centreIDs: ["Balham", "Tooting", "canons-leisure-centre", "Tolworth", "Cheam"]},
    "south": {regionName: "South", centreIDs: ["brixton-recreation-centre", "flaxman-sports-centre", "crystal-palace-leisure-centre", "waterfront-leisure-centre",]},
    "east": {regionName: "East", centreIDs: ["finsbury-leisure-centre", "ironmonger-row-baths", "britannia-leisure-centre", "walthamstow-leisure-centre", "clissold-leisure-centre", "Epping"]}
}

export const Locations: Record<CentreID, CourtLocation> = {
    "Balham": {
        provider: 'places',
        displayName: 'Balham Leisure Centre',
        alternativeSites: ['Tooting', 'brixton-recreation-centre', 'crystal-palace-leisure-centre', 'canons-leisure-centre', 'QueenMother'],
        transport: { mode: TransportMode.Tube, station: 'Balham' },
        coordinates: { lat: 51.4408, lng: -0.1519 },
        districtName: "Balham",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4973.8428624036915!2d-0.15724428559855416!3d51.441236775795666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605d00075d3d9%3A0x763d500ed52cf61d!2sBalham%20Leisure%20Centre%2C%20London!5e0!3m2!1sen!2suk!4v1734720750339!5m2!1sen!2suk"
    },
    "oasis-sports-centre": {
        provider: 'better',
        displayName: 'Oasis Leisure Centre',
        alternativeSites: ['finsbury-leisure-centre', 'ironmonger-row-baths', 'britannia-leisure-centre', 'Porchester', 'QueenMother'],
        transport:{mode: TransportMode.Tube, station: 'Tottenham Court Road'},
        coordinates: { lat: 51.5159, lng: -0.1257 },
        districtName: "Holborn",
        courtCount: 3,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.337967425549!2d-0.13207576353427167!3d51.51587353037817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b332df01831%3A0x58001512719480f3!2sOasis%20Sports%20Centre!5e0!3m2!1sen!2suk!4v1734720826076!5m2!1sen!2suk"
    },
    "Tooting": {
        provider: 'places',
        displayName: 'Tooting Leisure Centre',
        alternativeSites: ['Balham', 'canons-leisure-centre', 'crystal-palace-leisure-centre', 'brixton-recreation-centre', 'Cheam'],
        transport: {mode: TransportMode.Tube, station: 'Tooting Broadway'},
        coordinates: { lat: 51.4295, lng: -0.1730 },
        districtName: "Tooting",
        courtCount: 3,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4260.406214046232!2d-0.18012817410432264!3d51.42913761574621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876060849e6ed63%3A0x4ecc169b18978c06!2sTooting%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734720984595!5m2!1sen!2suk"
    },
    "Tolworth": {
        provider: 'places',
        displayName: 'Tolworth Recreation Centre',
        alternativeSites: ['Cheam', 'canons-leisure-centre', 'Tooting', 'Balham'],
        transport: {mode: TransportMode.NationalRail, station: 'Tolworth'},
        coordinates: { lat: 51.3747, lng: -0.2969 },
        districtName: "Tolworth",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31296.864046492286!2d-0.34504806840138363!3d51.378172116154325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760a3a52b08af5%3A0xeee178ea37de83a9!2sTolworth%20Recreation%20Centre!5e0!3m2!1sen!2suk!4v1734721101431!5m2!1sen!2suk"
    },
    "Epping": {
        provider: 'places',
        displayName: 'Epping Sports Centre',
        alternativeSites: ['walthamstow-leisure-centre', 'clissold-leisure-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Tube, station: 'Epping'},
        coordinates: { lat: 51.6933, lng: 0.1144 },
        districtName: "Epping",
        courtCount: 4,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6602.353059830133!2d0.09608914461371824!3d51.696195088911914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d898bafbbdb331%3A0xae1a3b8e148e89f4!2sEpping%20Sports%20Centre!5e0!3m2!1sen!2suk!4v1734721227440!5m2!1sen!2suk"
    },
    "britannia-leisure-centre": {
        provider: 'better',
        displayName: 'Britannia Leisure Centre',
        alternativeSites: ['finsbury-leisure-centre', 'ironmonger-row-baths', 'clissold-leisure-centre', 'oasis-sports-centre', 'walthamstow-leisure-centre'],
        transport: {mode: TransportMode.Overground, station: 'Hoxton'},
        coordinates: { lat: 51.5351, lng: -0.0791 },
        districtName: "Hoxton",
        courtCount: 4,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6537.2963296217495!2d-0.09194167820156593!3d51.53554053939546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca2a6a697cd%3A0x25a4ef965b86bd6b!2sBritannia%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721280997!5m2!1sen!2suk"
    },
    "swiss-cottage-leisure-centre": {
        provider: 'better',
        displayName: 'Swiss Cottage Leisure Centre',
        alternativeSites: ['oasis-sports-centre', 'Porchester', 'finsbury-leisure-centre', 'ironmonger-row-baths', 'kensington-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Swiss Cottage'},
        coordinates: { lat: 51.5433, lng: -0.1724 },
        districtName: "Swiss Cottage",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6285.866072736838!2d-0.18302419542904788!3d51.54135234923175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761a997ed2b38b%3A0x88dc157d4e952664!2sSwiss%20Cottage%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721338943!5m2!1sen!2suk"
    },
    "kensington-leisure-centre": {
        provider: 'better',
        displayName: 'Kensington Leisure Centre',
        alternativeSites: ['WestwayPortobello', 'Porchester', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'swiss-cottage-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Latimer Road'},
        coordinates: { lat: 51.5159, lng: -0.2104 },
        districtName: "North Kensington",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8113.943515577102!2d-0.22400839347188414!3d51.51355697251176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487610209fd3d96f%3A0xb44b0dfc4180f73b!2sKensington%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721418046!5m2!1sen!2suk"
    },
    "clissold-leisure-centre": {
        provider: 'better',
        displayName: 'Clissold Leisure Centre',
        alternativeSites: ['britannia-leisure-centre', 'walthamstow-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Overground, station: 'Stoke Newington'},
        coordinates: { lat: 51.5635, lng: -0.0774 },
        districtName: "Stoke Newington",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9921.984301999937!2d-0.10120991163815239!3d51.55913879999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761c7d71b6102d%3A0x504ce4c881d8f2a6!2sClissold%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721463943!5m2!1sen!2suk"
    },
    "walthamstow-leisure-centre": {
        provider: 'better',
        displayName: 'Walthamstow Leisure Centre',
        alternativeSites: ['clissold-leisure-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.Overground, station: 'St. James Street'},
        coordinates: { lat: 51.5828, lng: -0.0389 },
        districtName: "Walthamstow",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9918.337529152386!2d-0.047653712841796605!3d51.5758523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761dbf2ed64ea9%3A0xc95b6068cf717e51!2sWalthamstow%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721514801!5m2!1sen!2suk"
    },
    "crystal-palace-leisure-centre": {
        provider: 'better',
        displayName: 'Crystal Palace National Sports Centre',
        alternativeSites: ['Balham', 'brixton-recreation-centre', 'canons-leisure-centre', 'Tooting', 'flaxman-sports-centre'],
        transport: {mode: TransportMode.NationalRail, station: 'Crystal Palace'},
        coordinates: { lat: 51.4214, lng: -0.0726 },
        districtName: "Crystal Palace",
        courtCount: 3,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9952.074456898168!2d-0.08649461284179685!3d51.42108470000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487601685d729577%3A0xab6c3c3ae0044d1!2sCrystal%20Palace%20National%20Sports%20Centre!5e0!3m2!1sen!2suk!4v1734721563515!5m2!1sen!2suk"
    },
    "canons-leisure-centre": {
        provider: 'better',
        displayName: 'Canons Leisure Centre',
        alternativeSites: ['Tooting', 'Balham', 'crystal-palace-leisure-centre', 'Cheam'],
        transport: {mode: TransportMode.NationalRail, station: 'Mitcham Junction'},
        coordinates: { lat: 51.3895, lng: -0.1637 },
        districtName: "Mitcham",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9956.548189490968!2d-0.18197151284179297!3d51.4005365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760647d6ca0d7d%3A0x413de0247bda39ba!2sCanons%20Leisure%20Centre%20Mitcham!5e0!3m2!1sen!2suk!4v1734721600286!5m2!1sen!2suk"

    },
    "waterfront-leisure-centre": {
        provider: 'better',
        displayName: 'Waterfront Leisure Centre',
        alternativeSites: ['oasis-sports-centre', 'britannia-leisure-centre', 'finsbury-leisure-centre', 'ironmonger-row-baths'],
        transport: {mode: TransportMode.NationalRail, station: 'Woolwich'},
        coordinates: { lat: 51.4914, lng: 0.0693 },
        districtName: "Woolwich",
        courtCount: 4,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5715.993684299959!2d0.05315134609557765!3d51.49220609773969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a8934b5fc385%3A0xc4a7a162724be8b4!2sWaterfront%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721712521!5m2!1sen!2suk"
    },
    "hammersmith-fitness-squash-centre": {
        provider: 'better',
        displayName: 'Hammersmith Fitness and Squash Centre',
        alternativeSites: ['kensington-leisure-centre', 'WestwayPortobello', 'Porchester', 'oasis-sports-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Hammersmith'},
        coordinates: { lat: 51.4936, lng: -0.2256 },
        districtName: "Hammersmith",
        courtCount: 3,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5698.0069961768095!2d-0.22867140885157392!3d51.49183753289549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760fb8f66079e7%3A0x45d15a2730d4e246!2sHammersmith%20Fitness%20and%20Squash%20Centre!5e0!3m2!1sen!2suk!4v1734721767971!5m2!1sen!2suk"
    },
    "finsbury-leisure-centre": {
        provider: 'better',
        displayName: 'Finsbury Leisure Centre',
        alternativeSites: ['ironmonger-row-baths', 'britannia-leisure-centre', 'oasis-sports-centre', 'clissold-leisure-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Old Street'},
        coordinates: { lat: 51.5275, lng: -0.1044 },
        districtName: "Old Street",
        courtCount: 4,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4342.766949135214!2d-0.10066577059883754!3d51.52570786274248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b583d7b5b33%3A0x8a0f395809589fbc!2sFinsbury%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734721815792!5m2!1sen!2suk"
    },
    "brixton-recreation-centre": {
        provider: 'activeLambeth',
        displayName: 'Brixton Recreation Centre',
        alternativeSites: ['flaxman-sports-centre', 'QueenMother', 'Balham', 'oasis-sports-centre', 'crystal-palace-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Brixton'},
        coordinates: { lat: 51.4627, lng: -0.1146 },
        districtName: "Brixton",
        courtCount: 5,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4645.686515041023!2d-0.12087427070119511!3d51.46387928272745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876046640c1352d%3A0x7bab925e4631fb68!2sBrixton%20Recreation%20Centre!5e0!3m2!1sen!2suk!4v1734721878055!5m2!1sen!2suk"
    },
    "flaxman-sports-centre": {
        provider: 'activeLambeth',
        displayName: 'Flaxman Sports Centre',
        alternativeSites: ['brixton-recreation-centre', 'Balham', 'QueenMother', 'oasis-sports-centre', 'crystal-palace-leisure-centre'],
        transport: {mode: TransportMode.NationalRail, station: 'Loughborough Junction'},
        coordinates: { lat: 51.4684, lng: -0.1054 },
        districtName: "Camberwell",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6532.230509536949!2d-0.1095226186330407!3d51.472310457362774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876047dc46aab2f%3A0x7e1bff79d514235a!2sFlaxman%20Sports%20Centre%20-%20Active%20Lambeth!5e0!3m2!1sen!2suk!4v1734721936848!5m2!1sen!2suk"
    },
    "ironmonger-row-baths": {
        provider: 'better',
        displayName: 'Ironmonger Row Baths',
        alternativeSites: ['finsbury-leisure-centre', 'britannia-leisure-centre', 'oasis-sports-centre', 'clissold-leisure-centre', 'QueenMother'],
        transport:{mode: TransportMode.Tube, station: 'Old Street'},
        coordinates: { lat: 51.5314, lng: -0.0889 },
        districtName: "Old Street",
        courtCount: 2,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4592.611890341271!2d-0.10150819577191794!3d51.525685334196034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b5812155555%3A0xe51896dbaaae43ba!2sIronmonger%20Row%20Baths!5e0!3m2!1sen!2suk!4v1734722007417!5m2!1sen!2suk"
    },
    "QueenMother": {
        provider: 'everyoneActive',
        displayName: 'Queen Mother Sports Centre',
        alternativeSites: ['oasis-sports-centre', 'brixton-recreation-centre', 'Porchester', 'flaxman-sports-centre', 'hammersmith-fitness-squash-centre'],
        transport: {mode: TransportMode.Tube, station: 'Victoria'},
        coordinates: { lat: 51.4936, lng: -0.1444 },
        districtName: "Pimlico",
        courtCount: 3,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4968.1649483550955!2d-0.14935837884910083!3d51.49335400000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604dfffa95bf3%3A0x8ec3303e97d7dccd!2sQueen%20Mother%20Sports%20Centre!5e0!3m2!1sen!2suk!4v1734722048749!5m2!1sen!2suk"
    },
    "Porchester": {
        provider: 'everyoneActive',
        displayName: 'Porchester Centre',
        alternativeSites: ['WestwayPortobello', 'kensington-leisure-centre', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'QueenMother'],
        transport: {mode: TransportMode.Tube, station: 'Queensway'},
        coordinates: { lat: 51.5139, lng: -0.1918 },
        districtName: "Bayswater",
        courtCount: 1,
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6244.00510647858!2d-0.19980662941211108!3d51.51708386086057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761000883138ab%3A0xc5b95f5017d1595c!2sPorchester%20Centre!5e0!3m2!1sen!2suk!4v1734722106464!5m2!1sen!2suk"

    },
    "WestwayPortobello": {
        provider: 'everyoneActive',
        displayName: 'Westway Portobello Fitness Club',
        alternativeSites: ['Porchester', 'kensington-leisure-centre', 'hammersmith-fitness-squash-centre', 'oasis-sports-centre', 'swiss-cottage-leisure-centre'],
        transport: {mode: TransportMode.Tube, station: 'Ladbroke Grove'},
        coordinates: { lat: 51.5215, lng: -0.2084 },
        districtName: "Ladbroke Grove",
        courtCount: 2, //Todo
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6836.001927737759!2d-0.22078595512944194!3d51.51806835375637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876101f1cc3fc9d%3A0x8bf555d93994f9e7!2sWestway%20Portobello%20Fitness%20Club!5e0!3m2!1sen!2suk!4v1734722161922!5m2!1sen!2suk"
    },
    "Cheam": {
        provider: 'everyoneActive',
        displayName: 'Cheam Leisure Centre',
        alternativeSites: ['Tolworth', 'canons-leisure-centre', 'Tooting', 'Balham'],
        transport: {mode: TransportMode.NationalRail, station: 'West Sutton'},
        coordinates: { lat: 51.3594, lng: -0.2147 },
        districtName: "Sutton",
        courtCount: 2, //Todo
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17614.74791639697!2d-0.24507376774484718!3d51.36857962124855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876084832dde07d%3A0xc918f1368deb57e4!2sCheam%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734722226709!5m2!1sen!2suk"
    },
    "Harrow": {
        provider: 'everyoneActive',
        displayName: 'Harrow Leisure Centre',
        alternativeSites: ['swiss-cottage-leisure-centre', 'WestwayPortobello', 'kensington-leisure-centre', 'Porchester'],
        transport: {mode: TransportMode.Overground, station: 'Harrow & Wealdstone'},
        coordinates: { lat: 51.5883, lng: -0.3344 },
        districtName: "Harrow",
        courtCount: 2, //Todo
        embedMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7385.982782130897!2d-0.34238548967523663!3d51.59234642893216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761383d29e4735%3A0x22d6e580a3831620!2sHarrow%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1734722294963!5m2!1sen!2suk"
    }
}

export const getTransportLogoPath = (location: CentreID) => {
    switch (Locations[location].transport.mode) {
        case TransportMode.Tube: 
            return 'LULRoundel.png'
        case TransportMode.Overground: 
            return 'OvergroundRoundel.png'
        case TransportMode.NationalRail: 
            return 'NRlogo.png'
    }
}

export const getTransportLogoAltText = (location: CentreID) => {
    switch (Locations[location].transport.mode) {
        case TransportMode.Tube: 
            return 'London Underground Roundel'
        case TransportMode.Overground: 
            return 'Overground Roundel'
        case TransportMode.NationalRail: 
            return 'National Rail logo'
    }
}

export const getMapsLink = (location: CentreID) => {
    let name = Locations[location].displayName
    let nameReplaced = name.replace(/ /g, "+");
    var isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
        return `intent://maps.google.com/maps/search/?api=1&query=${nameReplaced},+London#Intent;scheme=https;package=com.google.android.apps.maps;end`
    } else {
        return `https://www.google.com/maps/search/?api=1&query=${nameReplaced},London`
    }
    
}

interface ProviderDisplayNames {
    better: string;
    places: string;
    activeLambeth: string;
    [key: string]: string;
}

export const providerDisplayNames: ProviderDisplayNames = {
    better: 'Better',
    places: 'Places Leisure',
    activeLambeth: 'Active Lambeth',
    everyoneActive: 'Everyone Active'
}

const placesUrl = (locationString: string, activity: string) => `https://www.placesleisure.org/centres/${locationString}/centre-activities/${activity}/#timetable`;
const betterUrl = (locationString: string, date: string) => `https://bookings.better.org.uk/location/${locationString}/squash-court-40min/${date}/by-time`;
const activeLambethUrl = (locationString: string, date: string) => `https://lambethcouncil.bookings.flow.onl/location/${locationString}/squash-court-40min/${date}/by-time`;
const everyoneActiveUrl = (locationCode: string) => `https://profile.everyoneactive.com/booking?Site=0${locationCode}&ActivityGroups=${locationCode}SQUASH&Culture=en-GB`

export const bookingLink = (location: CentreID, date: string) => {
    switch(Locations[location].provider) {
        case 'places':
            switch(location) {
                case 'Balham':
                    return placesUrl('balham-leisure-centre', 'squash');
                case 'Tooting':
                    return placesUrl('tooting-leisure-centre', 'sports');
                case 'Tolworth':
                    return placesUrl('tolworth-recreation-centre', 'sports');
                case 'Epping':
                    return placesUrl('epping-sports-centre', 'sports');
                }
            break;
        case 'better':
            return betterUrl(location, date);
        case 'activeLambeth':
            return activeLambethUrl(location, date);
        case 'everyoneActive':
            switch(location) {
                case 'QueenMother':
                    return everyoneActiveUrl('155');
                case 'Porchester':
                    return everyoneActiveUrl('153');
                case 'WestwayPortobello':
                    return everyoneActiveUrl('163');
                case 'Cheam':
                    return everyoneActiveUrl('073');
                case 'Harrow':
                    return everyoneActiveUrl('097');
            }
    }
} 

export const locationsByProvider: Record<string, [string, CourtLocation][]> = Object.entries(Locations).reduce((acc: Record<string, [string, CourtLocation][]>, [locationName, location]: [string, CourtLocation]) => {
    if (!acc[location.provider]) {
        acc[location.provider] = [];
    }
    acc[location.provider].push([locationName, location]);
    acc[location.provider].sort((a, b) => a[0].localeCompare(b[0]));
    return acc;
}, {});

// The number of days in the future that can be searched
export const providerSearchHorizon: Record<Provider, number> = {
    "activeLambeth": 6,
    "better": 5,
    "places": 8,
    "everyoneActive": 7
};

export const memberBookingHorizon: Record<Provider, number> = {
    "activeLambeth": 6,
    "better": 7,
    "places": 15,
    "everyoneActive": 7

};
