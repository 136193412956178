import React from "react";
import { Card, Col, Row } from "react-bootstrap";


function TextCard({content} : {content: JSX.Element}) {
    return (
        <Row style={{marginBottom: '1em'}}>
          <Col>
            <Card border='light' bg='light' style={{marginTop: '1em'}}>
              <Card.Body style={{ marginLeft:'0.5em', padding: '0.25em'}}>
                <Card.Text>
                  {content}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    )
}

export default TextCard;