import React, { Suspense, lazy } from 'react';
import {Container, Row, Col, Tab, Tabs} from 'react-bootstrap';
import Finder from './components/Finder';
import OnboardingModal from './components/OnboardingModal';
import Blurb from './components/Blurb';
import About from './components/About';
import TextCard from './components/TextCard';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Regions } from './utils/LocationsList';

const LocationInfoRow = lazy(() => import('./components/LocationInfoRow'));

function App() {
  return (
    <Router>
      <Container className="d-flex flex-column min-vh-100 ml-2">
        <Routes>
          <Route path="*" element={<AppContent/>} /> 
        </Routes>
      </Container>
    </Router>
  );
}

function AppContent() {
  const availabilityEndpoint = `${process.env.REACT_APP_BACKEND_HOST}/api`;

  const location = useLocation();

  const getHelmetContent = () => {
    switch (location.pathname) {
      case "/about":
        return {
          title: "About Squash Court Finder - London",
          description: "Learn more about Squash Court Finder. Our mission is to make finding and booking squash courts in London easier.",
          canonical: "https://www.squashfinder.co.uk/about"
        };
      default:
        if (location.pathname.startsWith("/locations/")) {
          const region = location.pathname.split("/")[2];
          const regionName = Regions[region].regionName;
          return {
            title: `Squash Court Finder - Squash Courts in ${regionName} London`,
            description: `Explore squash courts in ${regionName} London. Check real-time availability and book today.`,
            canonical: `https://www.squashfinder.co.uk/locations/${region}`
          };
        }
        return {
          title: "Squash Court Finder - Find & Book Courts in London",
          description: "Easily find and book public squash courts in London with Squash Court Finder. Search for courts at 23 different locations across 4 providers. Check real-time availability at local leisure centres and book a court today.",
          canonical: "https://www.squashfinder.co.uk"
        };
    }
  };

  const { title, description, canonical } = getHelmetContent();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" key="description" content={description} />
        <meta name="keywords" content="Squash, Squash Court, Squash Court Finder, London, Leisure Centre, Book Squash Court, Squash Court Availability, Find squash courts near me in London, Book public squash courts in London, where to play squash in london" />
        <link rel="canonical" key="canonical" href={canonical} />
        <meta property="og:title" content="Squash Court Finder - London" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://squashfinder.co.uk/squashfinder.webp" />
        <meta property="og:url" content={canonical} />
        <meta property="og:type" content="website" />
        <script type="application/ld+json" key="jsonld">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SportsActivityLocation",
            "name": {title},
            "description": "A tool to find and book public squash courts in London.",
            "url": "https://www.squashfinder.co.uk",
            "image": "https://squashfinder.co.uk/favicon.ico"
          })}
        </script>
      </Helmet>
      <AppBody availabilityEndpoint={availabilityEndpoint} location={location} />
      {/* <OnboardingModal/> */}
    </>
  )
}

function AppBody({availabilityEndpoint, location}) {
  const navigate = useNavigate();

  const activeMainTab = `/${location.pathname.split('/')[1]}`;
  const activeRegion = location.pathname.split('/locations/')[1] || 'west';

  const handleMainTabSelect = (key) => {
    if (key === "/locations") {
      navigate(`/locations/${activeRegion}`);
    } else {
      navigate(`${key}`);
    }
  };

  const handleRegionTabSelect = (key) => {
    navigate(`/locations/${key}`);
  };

  return (<>
<Row className="mt-2 align-items-center">
  <Col xs="auto">
    <img
      src="/squashfinder.webp"
      alt="Squash Court Finder logo showing a stylised squash racket"
      style={{ width: 'auto', height: '4.3em', marginRight: '0.8em' }}
    />
  </Col>
  <Col>
    <h1>Squash Court Finder</h1>
    <h3>London</h3>
  </Col>
</Row>
<Row>
  <Col>
    <Tabs
      className="flex-nowrap"
      activeKey={activeMainTab}
      id="main-tabs"
      onSelect={handleMainTabSelect}
      style={{marginTop: '0.5em'}}
    >
      <Tab eventKey="/" title={<span style={{padding:'0.1rem'}}>Find a court</span>}>
        <Row className="mt-1">
          <Col xs={12} lg={6} className="d-flex flex-column gap-1">
            <TextCard 
              content={
                <>
                  <article className="d-flex align-items-start mb-3" aria-label="Search">
                    <span className="me-2">🔍</span>
                    <p className="mb-0">
                      Search for a squash court at one of 23 different leisure centres across London.
                    </p>
                  </article>
                  <article className="d-flex align-items-start" aria-label="Availability">
                    <span className="me-2">🧭</span>
                    <p className="mb-0">
                      If no courts are available, we'll check other locations nearby.
                    </p>
                  </article>
                </>
              }
            />
            <Finder availabilityEndpoint={availabilityEndpoint} />
          </Col>
          
          <Col xs={12} lg={5} className="d-flex flex-column gap-1 ms-lg-5 mt-3 text-justify">
            <Blurb />
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="/locations" title="Locations">
        <Suspense fallback={<div>Loading Locations...</div>}> 
              <Tabs
                activeKey={activeRegion}
                id="locations-tabs"
                variant='underline'
                style={{marginLeft: '1em'}}
                onSelect={handleRegionTabSelect}
              >
                {Object.entries(Regions).map(([regionID, region]) => {
                  return (
                  <Tab key={regionID} eventKey={regionID} title={region.regionName}>
                    <LocationInfoRow locations={region.centreIDs}/>
                  </Tab>)
                })}
              </Tabs>
        </Suspense>
      </Tab>
      <Tab eventKey="/about" title="About">
          <Col xs={12} lg={8} >
            <Row>
              <About />
            </Row>
          </Col>
      </Tab>
  </Tabs>
  
  </Col>
  
</Row>
<Row className="mt-auto mb-1">
  <Col>
    <footer
      style={{
        fontSize: "0.7rem",
        backgroundColor: "#f8f9fa",
        textAlign: "center",
      }}
      role='contentinfo'
    >
      <section>
        <p>
          Disclaimer: Squash Court Finder is not affiliated with or endorsed by any
          of the listed leisure centres. We provide information on squash court availability 
          based on publicly available data from their websites, and all bookings must be 
          completed directly on their platforms.
        </p>
      </section>
      <section>
        <p>
          Contact the developer:{" "}
          <a href="mailto:info@squashfinder.co.uk">info@squashfinder.co.uk</a>
        </p>
      </section>
      
    </footer>
  </Col>
</Row>
  
  </>);
}

export default App;
