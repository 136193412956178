import React from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { CentreID, Locations, locationsByProvider, providerDisplayNames, providerSearchHorizon } from "../utils/LocationsList";
import { formatDate, timeOptions, dateOptions } from "../utils/Formatting";
import TextCard from "./TextCard";
import { Coords } from "../utils/Geography";

type FinderSearchProps = {
    selectedDate: string,
    selectedTime: string,
    selectedSearchMethod: string,
    selectedLocation: CentreID,
    outsideArea: boolean,
    searchTooFarOut: boolean,
    onSelectDate: (selected: string) => void,
    onSelectTime: (selected: string) => void,
    onSelectSearchMethod: (selected: string) => void,
    onSelectLocation: (selected: HTMLCollectionOf<HTMLOptionElement>) => void,
    onSetUserCoords: (location: Coords) => void,
    onSearch: () => void,
    isLoading: boolean
}

function FinderSearch({
    selectedDate, 
    selectedTime, 
    selectedLocation, 
    selectedSearchMethod, 
    outsideArea,
    searchTooFarOut,
    onSelectDate, 
    onSelectTime, 
    onSelectSearchMethod, 
    onSelectLocation, 
    onSetUserCoords,
    onSearch, 
    isLoading}: FinderSearchProps) {

    const locationOptions = () => {
        return Object.keys(locationsByProvider).map(provider => (
            <optgroup key={provider} label={providerDisplayNames[provider]}>
                {locationsByProvider[provider].map(([locationName, location]) => (
                    <option key={locationName} value={locationName} >
                        {location.displayName}
                    </option>
                ))}
                
            </optgroup>
        ));
    }

    const useDeviceLocation = () => {
        onSelectSearchMethod('location');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              onSetUserCoords({ lat: latitude, lng: longitude });
            },
            (error) => {
              console.error('Error detecting location: ', error);
              alert('Unable to detect your location.');
            }
          );
        } else {
          alert('Geolocation is not supported by your browser.');
        }
      };

    return (
        <>
            <Row>
                <Col>
                <Form>
                    <Form.Group>
                        <Form.Label style={{ marginBottom: '2px' }}>Date</Form.Label>
                        <Form.Select style={{width: '100%'}} onChange={({target}) => {onSelectDate(target.value)}} value={selectedDate}>
                            {dateOptions.map((d) => 
                                <option key={d.toLocaleString()} value={d.toISODate() as string}>
                                    {formatDate(d)}
                                </option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{marginTop: 10}}>
                        <Form.Label style={{ marginBottom: '2px' }}>Time</Form.Label>
                        <Form.Select style={{width: '100%'}} onChange={({target}) => {onSelectTime(target.value)}} value={selectedTime}>
                            {timeOptions.map((t) => 
                                <option key={t} value={t}>
                                   {t}
                                </option>)
                            }
                        </Form.Select>
                    </Form.Group>
                    <Row style={{marginLeft: 5, marginTop: 15, marginBottom: 10}} className="text-center">
                        <Col xs={12} md="auto" className="mb-2 mb-md-0">
                            <Row>
                                <Col xs={12} md="auto" className="mb-2 mb-md-0">
                                    <Row>
                                        <Button
                                            style={{ width: '12em', marginTop: 5, marginRight: 10}}
                                            size = 'sm'
                                            variant={selectedSearchMethod === 'centre' ? 'primary' : 'outline-primary'}
                                            onClick={() => onSelectSearchMethod('centre')}
                                            >
                                            Select a Leisure Centre
                                        </Button>
                                    </Row>
                                </Col>
                                { selectedSearchMethod === 'centre' && (
                                            <Col xs={12} md="auto" className="mt-2 mt-md-0" style={{marginLeft: 0, paddingLeft: 0}}>
                                                <Form.Select value={selectedLocation} onChange={({ target }) => { onSelectLocation(target.selectedOptions) }}>
                                                    {locationOptions()}
                                                </Form.Select>
                                            </Col>
                                )}   
                            </Row>
                            <Row style={{marginLeft: 50, marginTop: 10}} className="text-center">
                                <div>
                                    <p style={{ display: 'flex' }}>or</p>
                                </div>
                            </Row>
                            <Row style={{marginTop: 0}}>
                                <Button 
                                style={{ width: '12em' }}
                                size = 'sm'
                                variant={selectedSearchMethod === 'location' ? 'primary' : 'outline-primary'}
                                onClick={useDeviceLocation}
                                >
                                    📍 Use My Location
                                </Button>
                            </Row>
                            
                        </Col>
                    </Row>
                </Form>
                </Col>
            </Row>
            {outsideArea && (
                <TextCard content={
                    <div className="d-flex align-items-start mb-1">
                        <span className="me-2">🚫</span>
                        <span className='fs-6'>It looks like you're located outside of our search area.</span>
                    </div>
                } />
            )}
            {searchTooFarOut && selectedSearchMethod === "centre" && (
                <TextCard content={
                    <div className="d-flex align-items-start mb-1">
                        <span className="me-2">🚫</span>
                        <span className='fs-6'>Courts at your selected location can only be searched {providerSearchHorizon[Locations[selectedLocation].provider]} days ahead.</span>
                    </div>
                } />
            )}
            {searchTooFarOut && selectedSearchMethod === "location" && (
                <TextCard content={
                    <div className="d-flex align-items-start mb-1">
                        <span className="me-2">🚫</span>
                        <span className='fs-6'>Your nearest courts, at {Locations[selectedLocation].displayName}, can only be searched {providerSearchHorizon[Locations[selectedLocation].provider]} days ahead.</span>
                    </div>
                } />
            )}
            <Row style={{marginTop: 10, marginBottom: 10}}>
                <Col>
                <Button variant="primary" onClick={onSearch} disabled={searchTooFarOut || outsideArea} >
                    {isLoading && <>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        <span>Loading...</span>
                    </>
                    }
                    {
                      !isLoading && <span>Search</span>
                    }
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default FinderSearch;